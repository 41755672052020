import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

function OurProducts({ perfomanceImg, insightsImg, carreersImg, safetyImg, heading, linkText, intersectionImg }) {
  return (
    <div className="relative text-[#fff] bg-[#0064bf] w-full m-[auto]">
      <div className="pt-10 md:pt-0 pb-8 m-[auto] max-w-12/14">
        <div className="w-full">
          <div className="xl:pt-16 md:pt-8 font-extrabold xl:pb-4 xl:text-4xl pl-4 text-3xl md:text-2xl text-center pb-5 mb-6 text-[#ffffff] xl:pb-[0px] xl:pl-4 md:text-left">
            {heading}
          </div>
        </div>
        <div className="flex justify-center items-center flex-col md:flex-row">
          <div className="pb-5 md:w-3/12 text-center md:text-left md:px-4">
            <GatsbyImage image={perfomanceImg} alt="perfomanceimage" />
            <div className="sm:h-7 h-auto md:h-16 md:pt-4 md:text-base md:text-left lg:h-7 lg:pb-[14%] xl:pb-[8%] leading-[1.6] pt-5 text-[#ffffff] text-2xl sm:text-base pb-0 sm:pb-[10%] font-extrabold">
              everyday performance
            </div>
            <Link
              className="text-[#ffda00] hover:text-[#337ab7] font-bold text-base"
              to="https://entomo.co/solutions/everyday-performance/"
            >
              {linkText}
            </Link>
          </div>
          <div className="pb-5 md:w-3/12 text-center md:text-left md:px-4">
            <GatsbyImage image={insightsImg} alt="insightimage" />
            <div className="sm:h-7 h-auto md:h-16 md:pt-4 md:text-base md:text-left lg:h-7 lg:pb-[14%] xl:pb-[8%] leading-[1.6] pt-5 text-[#ffffff] text-2xl sm:text-base pb-0 sm:pb-[10%] font-extrabold">
              everyday insights
            </div>
            <Link
              className="text-[#ffda00] hover:text-[#337ab7] font-bold text-base"
              to="https://entomo.co/solutions/everyday-insights/"
            >
              {linkText}
            </Link>
          </div>
          <div className="pb-5 md:w-3/12 text-center md:text-left md:px-4">
            <GatsbyImage image={carreersImg} alt="carrerimage" />
            <div className="sm:h-7 h-auto md:h-16 md:pt-4 md:text-base md:text-left lg:h-7 lg:pb-[14%] xl:pb-[8%] leading-[1.6] pt-5 text-[#ffffff] text-2xl sm:text-base pb-0 sm:pb-[10%] font-extrabold">
              careers tomorrow
            </div>
            <Link
              className="text-[#ffda00]  hover:text-[#337ab7] font-bold text-base"
              to="https://entomo.co/solutions/careers-tomorrow/"
            >
              {linkText}
            </Link>
          </div>
          <div className="pb-5 md:w-3/12 text-center md:text-left md:px-4">
            <GatsbyImage image={safetyImg} alt="safetyimage" />
            <div className="sm:h-7 h-auto md:h-16 md:pt-4 md:text-base md:text-left lg:h-7 lg:pb-[14%] xl:pb-[8%] leading-[1.6] pt-5 text-[#ffffff] text-2xl sm:text-base pb-0 sm:pb-[10%] font-extrabold">
              first safety
            </div>
            <Link
              className="text-[#ffda00]  hover:text-[#337ab7] font-bold text-base"
              to="https://entomo.co/solutions/careers-tomorrow/"
            >
              {linkText}
            </Link>
          </div>
        </div>
      </div>
      <div>
        <GatsbyImage
          className="absolute w-[14%] m-0 bottom-0 right-0 intersection-image"
          image={intersectionImg}
          alt="interSectionImage"
        />
      </div>
    </div>
  );
}
OurProducts.propTypes = {
  perfomanceImg: PropTypes.string.isRequired,
  insightsImg: PropTypes.string.isRequired,
  carreersImg: PropTypes.string.isRequired,
  safetyImg: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  intersectionImg: PropTypes.string.isRequired,
};
export default OurProducts;
