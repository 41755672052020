import React from "react";
import { BiChevronRight } from "react-icons/bi";
import PropTypes from "prop-types";
import Button from "../components/Button";
import { GatsbyImage } from "gatsby-plugin-image";

function LookingForMoreInfo({ centerImg, heading, leftSideText, rightSideText }) {
  return (
    <div className="bg-[#0064bf] pt-8">
      <div className="text-center text-3xl mb-6 text-white md:text-3xl xl:text-4xl font-extrabold">
        <h2>{heading}</h2>
      </div>
      <div className="mx-auto flex items-center text-center max-w-12/14 flex-col sm:flex-col md:flex-row">
        <div className="sm:w-1/3 text-base mb-[0] leading-1.6 font-normal text-[#ffffff] xl:text-3xl ">
          <p className="mb-[0] pb-5 md:text-base text-base xl:text-base">{leftSideText}</p>
          <Button
            link="https://entomo.co/request-demo/"
            text="check it out"
            style={{ backgroundColor: "#ffda00", color: "black" }}
            sideImg={<BiChevronRight />}
          ></Button>
        </div>
        <div className="sm:w-1/3 text-base mb-[0] leading-[1.6] sm:block md:hidden font-normal text-[#ffffff] mt-6">
          <p className="mb-[0] pb-5 md:text-base text-base">{rightSideText}</p>
          <Button
            link="https://entomo.co/request-demo/"
            text="book a demo"
            style={{ backgroundColor: "#ffda00", color: "black" }}
            sideImg={<BiChevronRight />}
          ></Button>
        </div>

        <div className="sm:w-1/3 text-base mt-6 ">
          <GatsbyImage className="xl:inline-block" image={centerImg} alt="centerimage" />
        </div>
        <div className="w-1/3 text-base mb-[0] leading-[1.6] hidden sm:hidden md:block font-normal text-[#ffffff] xl:text-3xl">
          <p className="mb-[0] pb-5 md:text-base xl:text-base">{rightSideText}</p>
          <Button
            link="https://entomo.co/request-demo/"
            text="book a demo"
            style={{ backgroundColor: "#ffda00", color: "black" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </div>
    </div>
  );
}
LookingForMoreInfo.propTypes = {
  centerImg: PropTypes.string,
  heading: PropTypes.string,
  leftSideText: PropTypes.string,
  rightSideText: PropTypes.string,
};
export default LookingForMoreInfo;
