import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../../containers/global/layout";
import Seo from "../../components/seo";
import SolutionsEnterprises from "../../components/SolutionsEnterprises";
import OurProducts from "../../components/OurProducts";
import LookingForInspiration from "../../containers/LookingForInspiration";
import LookingForMoreInfo from "../../containers/LookingForMoreInfo";

const IndexPage = ({ data }) => {
  const solutionsEnterprises = data.solutionsEnterprises.nodes[0];
  const ourProducts = data.ourProducts.nodes[0];
  const lookingForInspiration = data.lookingForInspiration.nodes;
  const lookingForMoreInfo = data.lookingForMoreInfo.nodes[0];
  return (
    <Layout>
      <Seo title="Solutions" />
      <SolutionsEnterprises
        SolutionsBgImg={solutionsEnterprises.desktopImage.gatsbyImageData}
        SolutionsMobImg={solutionsEnterprises.mobileImage.gatsbyImageData}
        title={solutionsEnterprises.title}
        subtitle={solutionsEnterprises.subTitle}
      />
      <OurProducts
        perfomanceImg={ourProducts.perfomanceImg.gatsbyImageData}
        insightsImg={ourProducts.insightImg.gatsbyImageData}
        carreersImg={ourProducts.carrersImg.gatsbyImageData}
        safetyImg={ourProducts.safetyImg.gatsbyImageData}
        intersectionImg={ourProducts.interSctionImg.gatsbyImageData}
        heading={ourProducts.heading}
        linkText={ourProducts.linkText}
      />
      <LookingForInspiration
        title="looking for inspiration?"
        description="see how some of our customers are getting
      tomorrow-ready"
        storyData={[
          {
            image: lookingForInspiration[0].storyOne.gatsbyImageData,
            text: "Democratized insights, gamification for accelerated productivity for 24,000-plus employees",
            fullStoryLink:
              "https://entomo.co/success-stories/democratized-insights-gamification-for-accelerated-productivity-for-24000-plus-employees/",
          },
          {
            image: lookingForInspiration[0].storyTwo.gatsbyImageData,
            text: "Transforming employee engagement for over 60 business units for this ‘fastest-growing’ conglomerate",
            fullStoryLink:
              "https://entomo.co/success-stories/transforming-employee-engagement-for-over-60-business-units-for-this-fastest-growing-conglomerate/",
          },
        ]}
      />
      <LookingForMoreInfo
        centerImg={lookingForMoreInfo.centerImg.gatsbyImageData}
        heading={lookingForMoreInfo.heading}
        leftSideText={lookingForMoreInfo.leftSideText}
        rightSideText={lookingForMoreInfo.rightSideText}
      />
    </Layout>
  );
};
IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
export const query = graphql`
  query {
    solutionsEnterprises: allContentfulBannerContent(filter: { heading: { eq: "Solutions Enterprisess" } }) {
      nodes {
        title
        subTitle
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    ourProducts: allContentfulOurProducts {
      nodes {
        heading
        linkText
        carrersImg {
          gatsbyImageData(formats: WEBP)
        }
        insightImg {
          gatsbyImageData(formats: WEBP)
        }
        interSctionImg {
          gatsbyImageData(formats: WEBP)
        }
        perfomanceImg {
          gatsbyImageData(formats: WEBP)
        }
        safetyImg {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    lookingForInspiration: allContentfulLookingForInspiration(
      filter: { heading: { eq: "Solutions Looking for inspiration" } }
    ) {
      nodes {
        title
        description
        storyOne {
          gatsbyImageData(formats: WEBP)
        }
        storyTwo {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    lookingForMoreInfo: allContentfulLookingForMoreInfo {
      nodes {
        centerImg {
          gatsbyImageData(formats: WEBP)
        }
        heading
        leftSideText
        rightSideText
      }
    }
  }
`;
