import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

function SolutionsEnterprises({ title, subtitle, SolutionsBgImg, SolutionsMobImg }) {
  return (
    <div className="m-auto sm:mt-48 max-w-12/14 flex md:flex-row flex-col mb-8 grid-cols-2 md:mt-32 md:mb-16 xl:mb-8 xl:w-full xl:mt-[13%] 2xl:mt-48">
      <div className="lg:w-1/2">
        <h2 className="lg:w-10/12 text-left sm:text-center md:text-left sm:text-3xl leading-tight text-[#0064bf] font-extrabold text-2xl mb-2.5 xl:text-[2.5rem] xl:leading-tight xl:w-full md:pt-1 md:text-3xl 2xl:text-4xl">
          {title}
        </h2>
        <p className="lg:w-10/12 text-[#000000] leading-snug font-normal md:w-full sm:text-center md:text-left sm:text-sm md:text-base text-lg xl:w-4/6 2xl:w-3/5 xl:text-xl 2xl:text-2xl 2xl:pt-4">
          {subtitle}
        </p>
      </div>
      <div className="lg:w-1/2 xl:-ml-20">
        <GatsbyImage className="enterprise-banner" image={SolutionsBgImg} alt="solutionbgimage" />
        <GatsbyImage className="enterprise-bannermob" image={SolutionsMobImg} alt="solutionmobimage" />
      </div>
    </div>
  );
}
SolutionsEnterprises.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  SolutionsBgImg: PropTypes.string.isRequired,
  SolutionsMobImg: PropTypes.string.isRequired,
};
export default SolutionsEnterprises;
